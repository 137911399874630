:root {
	--manilla-color:#f1d592;
	--color-1:#000000;
	--color-2:#c83349;
	--color-3:#15b2d3;
	--color-4:#248507;
	--color-5:#f3872f;
}

html {
	font-size: 14px;
}

body {
	background-color: #ffffff;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
		Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	font-weight: normal;
	color: #000000;
	/* color: #495057; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	/* margin-bottom: 50px; */
	margin:0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 1.5rem 0 1rem 0;
	font-family: inherit;
	font-weight: 600;
	line-height: 1.2;
	color: inherit;
}

h1 {
	font-size: 2.5rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.75rem;
}
h4 {
	font-size: 1.5rem;
}
h5 {
	font-size: 1.25rem;
}
h6 {
	font-size: 1rem;
}
p {
	line-height: 1.5;
	margin: 0 0 1rem 0;
}
li {
	font-family: sans-serif;
	font-size:18px;
	font-weight: 500; 
}

.card {
	margin-bottom: 2rem;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

@keyframes pulse {
	0% {
		background-color: rgba(165, 165, 165, 0.1);
	}
	50% {
		background-color: rgba(165, 165, 165, 0.3);
	}
	100% {
		background-color: rgba(165, 165, 165, 0.1);
	}
}

.customer-badge {
	border-radius: 2px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
	background-color: #c8e6c9;
	color: #256029;
}

.customer-badge.status-unqualified {
	background-color: #ffcdd2;
	color: #c63737;
}

.customer-badge.status-negotiation {
	background-color: #feedaf;
	color: #8a5340;
}

.customer-badge.status-new {
	background-color: #b3e5fc;
	color: #23547b;
}

.customer-badge.status-renewal {
	background-color: #eccfff;
	color: #694382;
}

.customer-badge.status-proposal {
	background-color: #ffd8b2;
	color: #805b36;
}

.product-badge {
	border-radius: 2px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.3px;
}

.product-badge.status-instock {
	background: #c8e6c9;
	color: #256029;
}

.product-badge.status-outofstock {
	background: #ffcdd2;
	color: #c63737;
}

.product-badge.status-lowstock {
	background: #feedaf;
	color: #8a5340;
}

.order-badge {
	border-radius: 2px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.3px;
}

.order-badge.order-delivered {
	background: #c8e6c9;
	color: #256029;
}

.order-badge.order-cancelled {
	background: #ffcdd2;
	color: #c63737;
}

.order-badge.order-pending {
	background: #feedaf;
	color: #8a5340;
}

.order-badge.order-returned {
	background: #eccfff;
	color: #694382;
}

.image-text {
	vertical-align: middle;
	margin-left: 0.5rem;
}

.p-multiselect-representative-option {
	display: inline-block;
	vertical-align: middle;
}

.p-multiselect-representative-option img {
	vertical-align: middle;
	width: 24px;
}

.p-multiselect-representative-option span {
	margin-top: 0.125rem;
}

.p-column-filter {
	width: 100%;
}

.country-item {
	display: flex;
	align-items: center;
}

.country-item img.flag {
	width: 18px;
	margin-right: 0.5rem;
}

.flag {
	vertical-align: middle;
}

span.flag {
	width: 44px;
	height: 30px;
	display: inline-block;
}

img.flag {
	width: 30px;
}

/* My custom CSS */
.underline {
	text-decoration: underline;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.h-p67r {
	height:0.67rem;
	margin:0;
	padding:0;
}
.h-100px {
	height:100px;
}
.h-200px {
	height:200px;
}
.h-80pct {
	height:80%;
}
.h-100pct {
	height:100%;
}
.w-100px {
	width: 100px;
}
.w-200px {
	width: 200px;
}
.w-100pct {
	width: 100%;
}
.no-head >div >table>thead {
	display: none
} 
.p-row-expanded>td {
	padding: 0!important;
}
/* .p-datatable-thead>tr>th {
	padding: 0!important;
}  */
.category-list thead {
	display:none
}
th.adjustable-column>span {
	display:flex;
	width:100%
} 
/* .p-datatable .p-datatable-tbody>tr>td.p-left-8 {
	padding-left:8em;
}
.p-datatable .p-datatable-thead span.header-subcategories {
	padding-left:4em;
	width:2em;
} */

.bg-manilla {
	background-color: var(--manilla-color);
}
#adobe-dc-view {
	max-width:1200px;
	height:700px;
	margin:0 auto;
}
.iframe-container {
  max-width: 1200px;
	margin: 0 auto;
	height: 100vh;
  width: 100%;
}
.iframe-container iframe {
  width: 100%;
  height: 100%;
}
.p-datatable .p-datatable-thead>tr>th.p-sortable-disabled.column-header-no-pad {
	padding-left:0;
}
.mt-1r {
	margin-top: 1rem;
}
.mt-50 {
	margin-top:50px;
}
.h-50 {
	height:50px;
}

.bg-image {
	/* position:absolute;
	left:0;
	top:0; */
	height:100%;
	min-height:100vh;
	width:100%;
	z-index:-1;
	background-image: url('./assets/images/background.jpeg');
	opacity:1;
	background-position: center;
  background-repeat: repeat;
  /* background-size: cover; */
} 
.navbar-right {
	position: fixed;
	top:0;
	right:1rem;
}
.navbar-right .p-menu {
	background:#00000000;
}
.navbar-right .p-menu .p-menu-list .p-menuitem {
	margin-top:1rem;
	margin-bottom:1rem;
}
.navbar-right .p-menu .p-menu-list .p-menuitem .p-menuitem-link:hover {
	background-color: var(--color-4);
}
.navbar-right .p-menu .p-menu-list .p-menuitem .p-menuitem-text {
	color:#ffffff;
}
.navbar-right .p-menu {
	border:0;
	width: 9rem;
}

.navbar-notcontained {
	border-bottom-style:solid ;
	border-bottom-width: 1px;
	height:100%;
	width:100%;
	z-index:-1;
	background-image: url('./assets/images/background.jpeg');
	opacity:1;
	background-position: center;
  background-repeat: repeat;
}
.navbar-container .p-menubar {
	padding-left:0;
	padding-right: 0;
	height:96px;
	width:100%;
	display: flex;
	justify-content: space-between;
	background:#00000000;
	
}
.navbar-container .p-menubar-root-list {
	display: flex;
	justify-content: flex-end;
}
.navbar-container .p-menubar>.p-menubar-root-list>.p-menuitem>.p-menuitem-link {
	padding:18px;
	color:#ffffff;
	opacity:0.8;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
	color:#ffffff;
}
.navbar-container .p-menubar>.p-menubar-root-list>.p-menuitem>.p-menuitem-link:hover {
	background-color: var(--color-4);
	color:#ffffff;
}
.navbar-container ul > li.stand-out {
	background: #098824;
	padding-left:10px;
	padding-right:10px;
	margin-left:20px;
}
/* .navbar-container ul > li:last-of-type {
	background: #098824;
	padding-left:10px;
	padding-right:10px;
	margin-left:20px;
} */
.navbar-container .p-menubar span.p-menuitem-text,
.navbar-container .p-menubar>.p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-text {
	color:#ffffff;
}
#dashboard .p-datatable .p-datatable-thead > tr > th,
#dashboard .p-datatable .p-datatable-tbody > tr > td,
#contract-subject-card .p-datatable .p-datatable-tbody>tr,
#contract-subject-card .p-datatable .p-datatable-tbody>tr>td,
#contract-subject-card .p-datatable .p-datatable-thead>tr>th,
#contract-subject-card .p-datatable-thead>tr {
	background-color: var(--manilla-color);
	border:none;
}
#contract-subject-card .p-datatable .p-datatable-tbody > tr > td,
#contract-subject-card .p-datatable .p-datatable-thead > tr > th {
	padding:0.33rem 1rem;
}
.marg-l-21pct {
	margin-left:21%;
}
#editor-container {
  height: 200px;
}
.ql-editor> p {
	font-size: 18px;
}
.no-svg>svg {
	height:0;
	width:0;
}
.bg-color-1 {
	background-color: var(--color-1)!important;
}
.color-1 {
	color: var(--color-1)!important;
}
.bg-color-2 {
	background-color: var(--color-2)!important;
}
.color-2 {
	color: var(--color-2)!important;
}
.bg-color-3 {
	background-color: var(--color-3)!important;
}
.color-3 {
	color: var(--color-3)!important;
}
.bg-color-4 {
	background-color: var(--color-4)!important;
}
.color-4 {
	color: var(--color-4)!important;
}
.bg-color-5 {
	background-color: var(--color-5)!important;
}
.color-5 {
	color: var(--color-5)!important;
}
.pb-1p5 {
	padding-bottom: 1.5rem;;
}
.p-4 {
	padding: 4rem;;
}
.mt-0 {
	margin-top:0;
}
.color-error {
	color: #dc3545;
}
.button-pad-top {
	padding: 0.25em;
	margin:0;
}
.button-pad-bottom {
	padding: 0.25em;
	margin:0;
}
.container {
	width:100%;
	max-width:1200px;
	margin: auto;
}
.small-table .p-datatable .p-datatable-tbody > tr> td,
.p-y-025rem>.p-datatable-wrapper> table tr> td {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.h-50vh {
	height:50vh;
}
.h-min-600px {
	min-height:600px;
}
.m-0 {
	margin:0;
}
.fontl {
	font-size: 1.5rem;
	font-weight: 700;
}
#home-page-button {
	display:block;
}
